import styled from "styled-components";

import { theme } from "@byko/lib-styles";

import type { ContainerProps, HeadingProps, MainContentProps } from "./interface";

export const MainContent = styled.div<MainContentProps>`
  position: relative;
  z-index: 3;
  display: flex;
  width: 400px;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 36px;
  padding-bottom: 32px;
  background-color: ${theme.palette.blue.dark};
  gap: 64px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.blue.dark};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.yellow.main};
  }
  transform: translate3d(${(props): string => (props.show ? "0" : "-100vw")}, 0, 0);
  transition: 300ms;
  @media screen and (max-width: 1200px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    justify-content: center;
  }
  @media screen and (max-width: 1438px) {
    padding-top: 16px;
    padding-bottom: 64px;
  }
  @media screen and (max-height: 640px) and (min-width: 1438px) {
    padding-top: 16px;
    gap: 24px;
  }
  @media screen and (max-height: 480px) {
    padding-top: 164px;
    gap: 24px;
  }
`;

export const Heading = styled.div<HeadingProps>`
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 80px;
  padding-left: 80px;
  background-color: ${(props): string => (props.darkBackdrop ? theme.palette.blue.dark : theme.palette.white.main)};
  transition: 300ms;
  @media screen and (max-height: 640px) and (min-width: 1438px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media screen and (max-width: 1200px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    justify-content: ${(props): string => (props.darkBackdrop ? "space-between" : "flex-start")};
    padding-right: 24px;
    padding-left: 24px;
    border-bottom: 1px solid ${theme.palette.yellow.main};
    gap: ${(props): string => (props.darkBackdrop ? "0px" : "24px")};
  }
  @media screen and (min-width: 1300px) {
    h5 {
      font-size: 36px;
    }
  }
  @media screen and (max-width: 599px) {
    display: ${(props): string => (props.darkBackdrop ? "none" : "flex")};
  }
`;

export const NavigationOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 36px;
  margin-right: 80px;
  row-gap: 48px;
  @media screen and (max-height: 640px) and (min-width: 1438px) {
    padding-right: 24px;
    padding-left: 24px;
    row-gap: 36px;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 24px;
    margin-right: 24px;
    row-gap: 36px;
  }
  @media screen and (max-width: 480px) {
    padding-left: 24px;
    margin-right: 0px;
    row-gap: 28px;
  }
`;

export const CTABlock = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 32px;
  padding-left: 32px;
`;

export const CTABlockInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-height: 929px) {
    display: none;
  }
`;

export const CloseButtonWrapper = styled.button`
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    border-bottom: 2px solid ${theme.palette.yellow.main};
    content: "";
    transition: 300ms;
  }
  &:focus,
  &:hover {
    &:after {
      width: 100%;
    }
  }
  @media screen and (max-width: 898px) {
    display: none;
  }
`;

export const SubContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  transition: 300ms;
`;

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 88px;
  left: 0;
  display: flex;
  width: 100vw;
  height: calc(100% - 88px);
  flex-direction: row-reverse;
  transform: translate3d(${(props): string => (props.open ? "0" : "-100%")}, 0, 0);
  transition: ${(props): string => (props.open ? "300ms" : "1000ms")};
  transition-delay: ${(props): string => (props.open ? "0" : "200ms")};
  transition-timing-function: ease-in-out;
  ${SubContent} {
    flex-direction: row-reverse;
    opacity: ${(props): string => (props.open ? "1" : "0")};
    transition-delay: ${(props): string => (props.open ? "300ms" : "0")};
  }

  @media screen and (max-width: 1200px) {
    top: 144px;
    height: calc(100% - 144px);
    transition: 300ms;
    transition-delay: 0ms;
    ${SubContent} {
      transition-delay: 0ms;
    }
  }
  @media screen and (max-width: 1079px) {
    top: 112px;
    height: calc(100% - 112px);
  }
`;

export const MegamenuBackdrop = styled.img`
  position: absolute;
  bottom: 0;
  left: 100vw;
  height: 192px;
  transform: translate3d(-100%, 0, 0);
  @media screen and (max-width: 1200px) {
    height: 120px;
    transform: translate3d(-100%, 24px, 0);
  }
`;

export const ColumnContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr 1.1fr;
  @media screen and (max-width: 1200px) {
    display: block;
  }
`;

export const MobileContainer = styled.div``;
export const DesktopContainer = styled.div``;
export const MainContainer = styled.div`
  position: relative;
  z-index: 3000;
  ${DesktopContainer} {
    display: initial;
  }
  ${MobileContainer} {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    ${DesktopContainer} {
      display: none;
    }
    ${MobileContainer} {
      display: initial;
    }
  }
`;
